import * as Sentry from "@sentry/browser";
import Cookies from "js-cookie";

export const formatErrorMessage = (error: any) => {
	if (process.env.REACT_APP_NODE_ENV === "production") {
		Sentry.configureScope((scope) => {
			scope.setLevel("error");
			// scope.setLevel("fatal");
			scope.setUser({
				email: String(localStorage.getItem("user_id")),
				username: String(Cookies.get("full_name")),
			});
			scope.setTag("error type", "API error");
			Sentry.captureException(error.response);
		});
	}

	if (
		error.response &&
		error.response.data &&
		error.response.data._server_messages
	) {
		let errordata = JSON.parse(error.response.data._server_messages);
		let errorMessage = JSON.parse(errordata);
		return errorMessage.message;
	}

	if (error.response && error.response?.data?.message) {
		return error.response.data.message;
	}

	if (error.data && error.data._server_messages) {
		let errordata = JSON.parse(error.data._server_messages);
		let errorMessage = JSON.parse(errordata);
		return errorMessage.message;
	}

	if (error.message) {
		return error.message;
	}

	return error.toString();
};
