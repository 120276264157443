import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { ChakraProvider } from "@chakra-ui/react";
import { Provider } from "react-redux";
import { store } from "./packages";
import "./service-worker";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import * as Sentry from "@sentry/react";

if (process.env.REACT_APP_NODE_ENV === "production") {
	Sentry.init({
		dsn: "https://81194ef7ac8307017c1baf9883788481@o1134087.ingest.us.sentry.io/4508772840964096",
		integrations: [],
	});
}

const root = ReactDOM.createRoot(
	document.getElementById("root") as HTMLElement
);
root.render(
	<Provider store={store}>
		<ChakraProvider>
			<App />
		</ChakraProvider>
	</Provider>
);

serviceWorkerRegistration.register();
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
