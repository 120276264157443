/* eslint-disable no-restricted-globals */
import { manifest, version } from "@parcel/service-worker";
import { precacheAndRoute } from "workbox-precaching";

precacheAndRoute(self.__WB_MANIFEST || []);
async function install() {
	const cache = await caches.open(version);
	await cache.addAll(manifest);
}
addEventListener("install", (e) => e.waitUntil(install()));

async function activate() {
	const keys = await caches.keys();
	await Promise.all(keys.map((key) => key !== version && caches.delete(key)));
}

addEventListener("activate", (e) => e.waitUntil(activate()));

addEventListener("message", (event) => {
	if (event.data && event.data.type === "SKIP_WAITING") {
		self.skipWaiting();
	}
});
/* eslint-enable no-restricted-globals */
