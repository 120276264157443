import axios from "axios";

export const fetchSubProcesses = () => {
	return axios.get(
		"https://global.karkhana.io/capabilities/get_manufacturing_sub_process",
		{
			params: {
				platform_id: 5,
			},
		}
	);
};

export const fetchMaterialGrade = () => {
	return axios.get(
		"https://global.karkhana.io/capabilities/get_material_grade",
		{
			params: {
				platform_id: 5,
			},
		}
	);
};

export const fetchSurfaceFinish = (materialGradeName: string) => {
	return axios.get(
		"https://global.karkhana.io/capabilities/get_surface_finish",
		{
			params: {
				platform_id: 5,
				materialgrade_name: materialGradeName,
			},
		}
	);
};

export const fetchTolerance = () => {
	return axios.get("https://global.karkhana.io/capabilities/get_tolerance", {
		params: {
			platform_id: 5,
		},
	});
};
