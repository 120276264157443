import * as React from "react";
import {
	Box,
	Button,
	Img,
	Text,
	Tooltip,
	useMediaQuery,
} from "@chakra-ui/react";
import { AppDispatch, ERROR_MSG, SET_MESSAGE } from "../../packages";
import FileUploadAnimation from "../../img/FileUploadAnimation.gif";
import { useDropzone } from "react-dropzone";
import { useDispatch } from "react-redux";

export const FileUpload = (props: any) => {
	const dispatch = useDispatch<AppDispatch>();
	// const [currentPlan] = useSelector((state: any) => [state.subscriptionPlan]);
	const [files, setFiles] = React.useState<File[]>([]);
	const [isMobileTablet] = useMediaQuery("(max-width: 768px)");

	const onDrop = React.useCallback(
		(acceptedFiles: any, fileRejections: any) => {
			let isLargeFile = 0,
				fileNotSupported = 0,
				invalidFileFormat = 0;

			// Check rejected files
			fileRejections.forEach((file: any) => {
				file.errors.forEach((err: any) => {
					if (err.code === "file-too-large") {
						isLargeFile++;
					}
					if (err.code === "file-invalid-type") {
						fileNotSupported++;
					}
				});
			});

			// Filter accepted files to remove unsupported formats
			acceptedFiles = acceptedFiles.filter((file: any) => {
				const fileExt = file.name?.split(".").pop()?.toLowerCase();
				if (fileExt !== "step" && fileExt !== "stp") {
					fileRejections.push(file);
					return false;
				}
				return true;
			});

			// Handle errors
			if (
				isLargeFile > 0 &&
				fileNotSupported === 0 &&
				invalidFileFormat === 0
			) {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data:
							isLargeFile === 1
								? "Whoa, that's a heavyweight file! 🏋️ Please keep it under 50MB."
								: "Some files are too large! Please keep them under 50MB.",
					},
				});
			} else if (
				isLargeFile === 0 &&
				(fileNotSupported > 0 || fileRejections.length > 0)
			) {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data:
							fileRejections?.length > 0
								? "Unsupported file format detected! Please upload .STEP or .STP files only."
								: "ACE Alert: Some files are not yet part of our revolution. Please ensure all uploaded files are .STEP or .STP.",
					},
				});
			} else if (
				isLargeFile > 0 &&
				(fileNotSupported > 0 || fileRejections.length > 0)
			) {
				dispatch({
					type: SET_MESSAGE,
					payload: {
						case: ERROR_MSG,
						data: "Some files are either too large (>50MB) or not in .STEP/.STP format.",
					},
				});
			}

			// Process valid files
			if (acceptedFiles.length > 0) {
				props.handleFileUpload(acceptedFiles);
				setFiles(acceptedFiles);
			}
		},
		[props]
	);

	const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
	const {
		getRootProps,
		getInputProps,
		open,
		fileRejections,
		isDragActive,
		isDragAccept,
		isDragReject,
	} = useDropzone({
		onDrop,
		// Disable click and keydown behavior
		noClick: true,
		noKeyboard: true,
		multiple: true,
		accept: isMobile
			? undefined
			: {
					"model/step": [".stp", ".STP", ".step", ".STEP"], // STEP file-specific format
					"application/octet-stream": [".stp", ".STP", ".step", ".STEP"], // Generic binary files
					"application/STEP": [".stp", ".STP", ".step", ".STEP"],
			  },
		minSize: 10,
		maxSize: 52428799,
		// disabled:
		// 	currentPlan?.plan_status === "Expired" ||
		// 	currentPlan?.used_file_uploads == currentPlan?.allocated_file_uploads,
	});

	return (
		<Box
			display="flex"
			flexDir="column"
			justifyContent="center"
			alignItems="center"
			py="3rem"
			w="100%"
			bg="#0087ee30"
			border="1px dashed #0087ee"
			gap="0.5rem"
			borderRadius="lg"
			{...getRootProps()}
		>
			<Img src={FileUploadAnimation} alt="file-upload-animation" />
			<Text onClick={open} textAlign="center">
				<span style={{ fontWeight: "500" }}>Drag and drop</span> files to
				upload, or
			</Text>
			<Tooltip
			// label={
			// 	currentPlan?.plan_status === "Expired" ||
			// 	currentPlan?.used_file_uploads == currentPlan?.allocated_file_uploads
			// 		? "Your Limit Exceeded/Expired, Please Upgrade Now!"
			// 		: ""
			// }
			>
				<Button
					className="aceFileUploadInitiateBtn"
					id="ace_file_upload_initiate_btn"
					fontWeight={"600"}
					color="#fff"
					w={isMobileTablet ? "10rem" : "14.4375rem"}
					bgColor="#0087ee"
					_hover={{ bg: "#006DC0" }}
					onClick={open}
					// isDisabled={
					// 	currentPlan?.plan_status === "Expired" ||
					// 	currentPlan?.used_file_uploads ==
					// 		currentPlan?.allocated_file_uploads
					// }
				>
					Get Instant Cost
				</Button>
			</Tooltip>
			<input {...getInputProps({})} type="file" />
			{isMobileTablet ? (
				<Box textAlign="center">
					<Text>
						<span style={{ fontWeight: "500" }}>File types:</span>&nbsp;STEP,
						STP
					</Text>
					<Text>
						<span style={{ fontWeight: "500" }}>File size:</span>&nbsp;
						{`< 50 MB`}
					</Text>
				</Box>
			) : (
				<Text fontWeight="500">
					{`File types: STEP, STP | File size: < 50 MB`}
				</Text>
			)}
			<Text color="#00000050" fontSize=".75rem" textAlign="center">
				All your files are entirely secured with us&nbsp;
				{isMobileTablet && <br />}(
				<span style={{ color: "#0087ee", textDecoration: "underline" }}>
					read our privacy guarantee
				</span>
				)
			</Text>
		</Box>
	);
};
