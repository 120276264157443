import React from "react";
import { Box, Button, Flex, Img, Text } from "@chakra-ui/react";
import shield from "../img/shield-zap.svg";
import checkVerified from "../img/check-verified.svg";
import arrayIcon from "../img/arr.svg";
import onBoardingImg1 from "../img/onboardingImg1.png";
import onBoardingImg2 from "../img/onboardingImg2.png";
import Kkonnect from "../img/Kkonnect_logo_with_text_white.svg";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Scrollbar, A11y, Autoplay } from "swiper";

import "swiper/swiper-bundle.min.css";
import "swiper/swiper.min.css";

const Onboarding = ({ skipOnboarding }: any) => {
	const [isLoading, setIsLoading] = React.useState<boolean>(true);
	const [cur, setCur] = React.useState<number>(0);
	const slides = [
		{
			img: onBoardingImg1,
			icon: shield,
			text: (
				<>
					Instant Cost Estimation <br /> with Detailed Breakdown <br />
					from Your CAD File
				</>
			),
		},
		{
			img: onBoardingImg2,
			icon: checkVerified,
			text: (
				<>
					Get Manufacturing <br /> Recommendations Tailored <br />
					to Your CAD Designs
				</>
			),
		},
	];

	React.useEffect(() => {
		const timer = setTimeout(() => {
			setIsLoading(false);
		}, 2000);
		return () => clearTimeout(timer);
	}, []);

	return (
		<Box
			w="100vw"
			h="100vh"
			bg="linear-gradient(90deg, #0F56AA 0.01%, #1462BF 20%, #1A71D9 50.99%, #1E7BE8 77.98%, #1F7EEE 99.97%)"
		>
			{isLoading ? (
				<Flex alignItems="center" justifyContent="center" h="100vh">
					<Img src={Kkonnect} w="60%"></Img>
				</Flex>
			) : (
				<Flex
					flexDirection="column"
					alignItems="center"
					justifyContent="center"
					gap="1rem"
					h="100vh"
					w="100%"
				>
					<Box flex={1} w="100vw" h="55vh">
						<Flex justifyContent="flex-end">
							<Button
								id="ace_skip_onboarding_btn"
								variant="ghost"
								color="rgba(255, 255, 255, 0.6)"
								fontWeight=""
								_hover={{ bg: "transparent", textDecoration: "underline" }}
								children="Skip"
								onClick={() => skipOnboarding()}
							/>
						</Flex>
						<Flex alignItems="center" justifyContent="flex-end">
							<Swiper
								modules={[Pagination, Scrollbar, A11y, Autoplay]}
								spaceBetween={2}
								slidesPerView={1}
								autoplay={{ delay: 1500 }}
								loop={true}
								pagination={{ clickable: true }}
								onSlideChange={(swiper) => {
									setCur(swiper.realIndex);
								}}
								style={{ height: "50vh" }}
							>
								{slides?.map((each: any, index: number) => (
									<SwiperSlide key={index}>
										<Flex
											alignItems="center"
											justifyContent="flex-end"
											mb="2rem"
										>
											<Img src={each.img} objectFit="contain" />
										</Flex>
									</SwiperSlide>
								))}
							</Swiper>
						</Flex>
					</Box>
					<Box
						h="45vh"
						flex={1}
						width="100vw"
						borderRadius="1.75rem 1.75rem 0 0"
						display="flex"
						flexDir="column"
						alignItems="Center"
						justifyContent="center"
						background="linear-gradient(90deg, #286AB7 0%, #368BEF 100%)"
						backdropFilter="blur(40px)"
					>
						<Img src={slides[cur]?.icon} />
						<Text
							mt="1rem"
							color="#fff"
							textAlign="center"
							fontSize="1.375rem"
							lineHeight="1.75rem"
						>
							{slides[cur]?.text}
						</Text>
						<Button
							id="ace_get_started_to_register_btn"
							mt="2.75rem"
							w="75%"
							h="3.125rem"
							color="#0F5CAA"
							children="Get Started"
							onClick={() => skipOnboarding()}
						/>
					</Box>
				</Flex>
			)}
		</Box>
	);
};

export default Onboarding;
